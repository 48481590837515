import React from "react"
import { graphql } from "gatsby"
import { isEmptyRedirect } from "utils/redirect"
import PropTypes from "prop-types"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import PortfolioNavigation from "components/molecules/PortfolioNavigation"
import ProjectSnapshot from "components/content/Portfolio/ProjectSnapshot"
import ClientOnly from "components/atoms/ClientOnly"

// TODO add location - link state
const PortfolioSnapshotPage = ({
  data: {
    api: { project },
  },
  pageContext: { language },
  location,
}) => {
  const { overview } = project || {}
  isEmptyRedirect(overview)

  return (
    <Layout hideFooter isNavStatic language={language}>
      <SEO title="Project overview" />
      {overview && (
        <ClientOnly>
          <PortfolioNavigation projectData={project} location={location} />
          <ProjectSnapshot sectionData={project} />
        </ClientOnly>
      )}
    </Layout>
  )
}

PortfolioSnapshotPage.propTypes = {
  data: PropTypes.shape({
    api: PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
        primaryPropertyTag: PropTypes.string,
        projectTag: PropTypes.string,
        overview: PropTypes.shape({
          summary: PropTypes.string,
          features: PropTypes.arrayOf(
            PropTypes.shape({
              description: PropTypes.string,
              position: PropTypes.number,
            })
          ),
          land: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
        bannerImages: PropTypes.arrayOf(
          PropTypes.shape({
            bannerPosition: PropTypes.string,
            image: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
            id: PropTypes.string,
            viewAngle: PropTypes.oneOfType([
              PropTypes.shape({
                original: PropTypes.string,
                large: PropTypes.string,
                medium: PropTypes.string,
                small: PropTypes.string,
              }),
              PropTypes.string,
            ]),
          })
        ),
      }),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      selectedPropertyType: PropTypes.string,
      selectedProjectType: PropTypes.string,
    }),
  }).isRequired,
}

export const query = graphql`
  query ProjectSnapshotPageData($apiLocale: API_Locale, $projectSlug: String!) {
    api {
      project(slug: $projectSlug, language: $apiLocale) {
        id
        name
        slug
        primaryPropertyTag
        projectTag
        overview {
          summary
          features {
            description
            position
          }
          land
          building
        }
        bannerImages {
          bannerPosition
          image
          id
          viewAngle
        }

        ...projectTabs
      }
    }
  }
`

export default PortfolioSnapshotPage
