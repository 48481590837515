import styled, { css } from "styled-components"
import { ButtonBack, ButtonNext } from "pure-react-carousel"
import Image from "components/atoms/Image"

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`

const StyledCarouselWrapper = styled.div`
  padding-bottom: 2.4rem;
  position: relative;
`

const carouselButtonsStyles = css`
  position: absolute;
  top: calc(50% - 1.2rem);
  transform: translateY(-50%);
  border: none;
  outline: none;
  padding: 1.6rem;
  background-color: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 4.8rem;
  max-height: 4.8rem;

  > svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.color.secondary01};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.main03};

    > svg {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`

const StyledButtonBack = styled(ButtonBack)`
  ${carouselButtonsStyles}
  left: 0.8rem;
`
const StyledButtonNext = styled(ButtonNext)`
  ${carouselButtonsStyles}
  right: 0.8rem;
`
export {
  StyledImage,
  StyledCarouselWrapper,
  StyledButtonBack,
  StyledButtonNext,
}
