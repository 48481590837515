import styled, { css } from "styled-components"

const textStyles = css`
  color: ${({ theme }) => theme.color.secondary01};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;

  span[type="button"] {
    color: ${({ theme }) => theme.color.secondary01};
  }
`
const summaryTextStyles = css`
  hyphens: auto;
  text-align: justify;
`
const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  max-width: ${({ theme }) => theme.gridWidth};
  width: 100%;
  height: auto;
  margin: 1.6rem auto;

  ${({ theme }) => theme.mq.medium} {
    height: 57.6rem;
    flex-direction: row;
    padding: 0 0 0 ${({ theme }) => theme.layout.mobilePadding};
  }
`

const StyledImageWrapper = styled.div`
  width: 100%;
  height: 20.8rem;
  margin-right: 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    flex: 0 1 76.8rem;
    height: 57.6rem;
  }
`

const StyledBlocksWrapper = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 3.2rem;

  ${({ theme }) => theme.mq.medium} {
    grid-template-columns: 1fr;
  }

  ${({ theme }) => theme.mq.large} {
    grid-template-columns: 1fr 1fr;
  }
`

const StyledTextWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
  height: 100%;
  overflow-y: auto;

  ${({ theme }) => theme.mq.medium} {
    flex: 0 1 38.8rem;
    margin-right: -0.6rem;
    padding: 0 0.8rem 0 0;

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: ${({ theme }) => theme.color.secondary06};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.secondary03};
    }
  }
`

const StyledTextItem = styled.div`
  padding: 1.2rem 0;

  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
  }
`

const StyledLabel = styled.span`
  display: block;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.75;
  color: ${({ theme }) => theme.color.secondary01};
  padding-bottom: 0.8rem;
`

const StyledListItem = styled.li`
  ${({ order }) => order && `order: ${order};`};
  padding-bottom: 0.8rem;
  text-indent: -20px;
  margin-left: 20px;
`

const StyledListItemText = styled.span`
  ${textStyles};
  hyphens: auto;
  text-align: justify;
`

const StyledProjectBlock = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-template-rows: auto;

  > svg {
    grid-row: 1/3;
    width: 6.4rem;
    height: 6.4rem;
    margin-right: 1.6rem;
  }
`

const StyledBlockLabel = styled.span`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: ${({ theme }) => theme.color.secondary02};
  margin-top: 0.8rem;
`

const StyledBlockText = styled.span`
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  margin-bottom: 0.8rem;
`

export {
  textStyles,
  StyledSection,
  StyledImageWrapper,
  StyledTextWrapper,
  StyledTextItem,
  StyledLabel,
  StyledListItem,
  StyledListItemText,
  StyledProjectBlock,
  StyledBlockLabel,
  StyledBlockText,
  StyledBlocksWrapper,
  summaryTextStyles,
}
