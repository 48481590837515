import React from "react"
import PropTypes from "prop-types"

import {
  StyledImageGrid,
  StyledImageContainer,
  StyledImg,
} from "./DesktopImages.styles"

const DesktopImages = ({ images, handleOpenModal }) => {
  if (images.length >= 4) {
    return (
      <StyledImageGrid>
        {images.map((image, idx) => (
          <StyledImageContainer
            key={image.id}
            onClick={() => handleOpenModal(idx)}
          >
            <StyledImg src={image.image} alt={image.id} />
          </StyledImageContainer>
        ))}
      </StyledImageGrid>
    )
  }
  return (
    <StyledImageContainer>
      <StyledImg src={images[0].image} alt={images[0].id} />
    </StyledImageContainer>
  )
}

DesktopImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      bannerPosition: PropTypes.string,
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      id: PropTypes.string,
    })
  ).isRequired,
  handleOpenModal: PropTypes.func.isRequired,
}

export default DesktopImages
