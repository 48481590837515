import styled from "styled-components"
import Image from "components/atoms/Image"

const StyledImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(20rem, 36.8rem));
  grid-template-rows: repeat(2, minmax(20.6rem, 27.2rem));
  grid-gap: 3.2rem;
`

const StyledImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
`

const StyledImg = styled(Image)`
  width: 100%;
  height: 100%;
`

export { StyledImageGrid, StyledImageContainer, StyledImg }
