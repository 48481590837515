import React from "react"
import PropTypes from "prop-types"
import { CarouselProvider, Slider, Slide } from "pure-react-carousel"

import Icon from "components/atoms/Icon"

import {
  StyledImage,
  StyledCarouselWrapper,
  StyledButtonBack,
  StyledButtonNext,
} from "./MobileImages.styles"

const MobileImages = ({ images }) => {
  return (
    <StyledCarouselWrapper>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={37}
        totalSlides={images.length}
      >
        <Slider>
          {images.map((image, index) => (
            <Slide index={index} key={image.id}>
              <StyledImage src={image.image} alt={image.id} />
            </Slide>
          ))}
        </Slider>
        {images.length > 1 && (
          <>
            <StyledButtonBack>
              <Icon icon="chevronLeft" />
            </StyledButtonBack>
            <StyledButtonNext>
              <Icon icon="chevronRight" />
            </StyledButtonNext>
          </>
        )}
      </CarouselProvider>
    </StyledCarouselWrapper>
  )
}

MobileImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([
        PropTypes.shape({
          original: PropTypes.string,
          large: PropTypes.string,
          medium: PropTypes.string,
          small: PropTypes.string,
        }),
        PropTypes.string,
      ]),
      id: PropTypes.string,
    })
  ).isRequired,
}

export default MobileImages
