import { useIntl } from "gatsby-plugin-intl"

const getPropertyTypeDescription = (type, onlyId) => {
  const { formatMessage } = onlyId ? {} : useIntl()

  const typeMap = {
    RESIDENTIAL: "residential",
    RETAIL_FOOD: "retailFood",
    HOTEL: "hotel",
    MIXED_USE: "mixedUse",
    OFFICE_MEDICAL: "officeMedical",
    INDUSTRIAL_FLEX: "industrialFlex",
    ALL_TYPES: "allTypes",
  }

  const id = `propertyTypes.${typeMap[type]}`
  return onlyId
    ? id
    : formatMessage({
        id,
      })
}

export default getPropertyTypeDescription
