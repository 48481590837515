import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import P from "components/atoms/P"
import Ul from "components/atoms/Ul"
import Lightbox from "components/atoms/Lightbox"

import randomize from "utils/randomize"
import getProjectTypeDescription from "utils/getProjectTypeDescription"
import getPropertyTypeDescription from "utils/getPropertyTypeDescription"
import useWindowSize from "utils/useWindowSize"
import sortBy from "utils/sortBy"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import trackingCategoriesHelper from "utils/trackingCategoriesHelper"

import ImageSlider from "components/molecules/ImageSlider"
import ProjectBlock from "components/atoms/ProjectBlock"

import theme from "styles/theme"

import {
  StyledSection,
  StyledImageWrapper,
  StyledTextWrapper,
  StyledTextItem,
  StyledLabel,
  StyledListItem,
  StyledListItemText,
  StyledBlocksWrapper,
  summaryTextStyles,
} from "./ProjectSnapshot.styles"

import DesktopImages from "./DesktopImages"
import MobileImages from "./MobileImages"

const imageOrder = {
  TOP_LEFT: 0,
  TOP_RIGHT: 1,
  BOTTOM_LEFT: 2,
  BOTTOM_RIGHT: 3,
}

const ProjectSnapshot = ({ sectionData }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [images, setImages] = useState([])
  const { formatMessage } = useIntl()
  const size = useWindowSize()
  const {
    overview: { summary, features, land, building },
    bannerImages,
    projectTag,
    primaryPropertyTag,
  } = sectionData

  const handleOpenModal = (id) => {
    setSelectedImage(id)
    trackCustomEventTrigger({
      category: trackingCategoriesHelper.PROJECT_SNAPSHOT,
      action: "Click",
      label: `${trackingCategoriesHelper.PROJECT_SNAPSHOT} link(Lightbox)`,
    })
  }

  const handleCloseModal = () => {
    setSelectedImage(null)
  }

  useEffect(() => {
    const trimBannerImages = () => {
      if (bannerImages.length >= 4) {
        const getBannerImages = randomize(bannerImages, bannerImages.length)
          .filter(
            (image, index, self) =>
              self
                .map((x) => x.bannerPosition)
                .indexOf(image.bannerPosition) === index
          )
          .map((item) => ({
            ...item,
            position: imageOrder[item.bannerPosition],
          }))

        setImages(sortBy(getBannerImages, ["position"]))
      } else {
        setImages(bannerImages.slice(0, 1))
      }
    }
    trimBannerImages()
  }, [sectionData])

  const blockItems = [
    {
      iconName: "usage",
      label: formatMessage({
        id: "pages.portfolio.project.snapshot.propertyType",
      }),
      value: getPropertyTypeDescription(primaryPropertyTag),
    },
    {
      iconName: "projectType",
      label: formatMessage({
        id: "pages.portfolio.project.snapshot.projectType",
      }),
      value: getProjectTypeDescription(projectTag),
    },
    {
      iconName: "building",
      label: formatMessage({
        id: "pages.portfolio.project.snapshot.building",
      }),
      value: building,
    },
    {
      iconName: "land",
      label: formatMessage({
        id: "pages.portfolio.project.snapshot.land",
      }),
      value: land,
    },
  ]

  return (
    <>
      <StyledSection>
        {images.length && size.width >= theme.breakpoints.medium ? (
          <StyledImageWrapper>
            {bannerImages && (
              <DesktopImages
                images={images}
                handleOpenModal={handleOpenModal}
              />
            )}
          </StyledImageWrapper>
        ) : (
          <MobileImages images={bannerImages} />
        )}

        <StyledTextWrapper>
          <StyledBlocksWrapper>
            {blockItems.map(
              (block) =>
                block.value && (
                  <ProjectBlock blockData={block} key={block.label} />
                )
            )}
          </StyledBlocksWrapper>

          <StyledTextItem>
            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.snapshot.summary",
              })}
            </StyledLabel>
            <P extraStyles={summaryTextStyles}>{summary}</P>
          </StyledTextItem>
          <StyledTextItem>
            <StyledLabel>
              {formatMessage({
                id: "pages.portfolio.project.snapshot.features",
              })}
            </StyledLabel>
            <Ul>
              {features.map((feature) => (
                <StyledListItem key={feature.position} order={feature.position}>
                  <StyledListItemText>{feature.description}</StyledListItemText>
                </StyledListItem>
              ))}
            </Ul>
          </StyledTextItem>
        </StyledTextWrapper>
      </StyledSection>

      {images.length && size.width >= theme.breakpoints.medium && (
        <>
          {Number.isInteger(selectedImage) && (
            <Lightbox
              showModal={Number.isInteger(selectedImage)}
              handleCloseModal={handleCloseModal}
            >
              <ImageSlider
                bannerImages={images}
                selectedId={selectedImage}
                handleCloseModal={handleCloseModal}
                isIntrinsicHeight
                isLightbox
                trackingOptions={{
                  category: trackingCategoriesHelper.PROJECT_SNAPSHOT,
                  action: "Click",
                }}
              />
            </Lightbox>
          )}
        </>
      )}
    </>
  )
}

ProjectSnapshot.propTypes = {
  sectionData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    overview: PropTypes.shape({
      summary: PropTypes.string,
      features: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          position: PropTypes.number,
        })
      ),
      land: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      building: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    bannerImages: PropTypes.arrayOf(
      PropTypes.shape({
        bannerPosition: PropTypes.string,
        image: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
        id: PropTypes.string,
        viewAngle: PropTypes.oneOfType([
          PropTypes.shape({
            original: PropTypes.string,
            large: PropTypes.string,
            medium: PropTypes.string,
            small: PropTypes.string,
          }),
          PropTypes.string,
        ]),
      })
    ),
    projectTag: PropTypes.string,
    primaryPropertyTag: PropTypes.string,
  }).isRequired,
}

export default ProjectSnapshot
